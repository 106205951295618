import * as Plot from "@observablehq/plot";
import { useEffect, useRef } from "react";

export const ScatterChart = ({
  dataset,
  filtered,
  selectedFilter,
  scatterCodes = [],
  xtitle = "Title of X-axis",
  ytitle = "Title of Y-axis",
}) => {
  const containerRef = useRef();

  // only one mark that shows raw points
  let overall_marker = Plot.dot(dataset, {
    x: scatterCodes[0],
    y: scatterCodes[1],
    fill: "lightblue",
  });

  // two marks, one for the averages, one for the filter level text
  let filter_marker = [
    Plot.dot(
      dataset,
      Plot.groupZ(
        { x: "mean", y: "mean" },
        {
          x: scatterCodes[0],
          y: scatterCodes[1],
          fill: selectedFilter,
          tip: true,
        }
      )
    ),
    Plot.text(
      dataset,
      Plot.groupZ(
        { x: "mean", y: "mean", text: "first" },
        {
          x: scatterCodes[0],
          y: scatterCodes[1],
          dx: 5,
          z: selectedFilter,
          text: selectedFilter,
          textAnchor: "start",
        }
      )
    ),
  ];

  useEffect(() => {
    if (dataset === undefined) return;

    const plot = Plot.plot({
      // note that domain will come with the scale data in the future
      x: { },
      y: { domain: [1, 6] },
      color: { legend: true },
      aspectRatio: 1,
      marginBottom: 60,
      marginLeft: 60,

      marks: [
        Plot.axisX({
          anchor: "bottom",
          labelAnchor: "center",
          labelArrow: "None",
          fontSize: "14px",
          tickSpacing: 100,
          label: xtitle,
          
        }),
        Plot.axisY({
          anchor: "left",
          labelAnchor: "center",
          labelArrow: "None",
          fontSize: "14px",
          tickSpacing: 100,
          label: ytitle,  
        }),
        
        Plot.ruleX([4], { strokeWidth: 1.5, stroke: "gray" }),
        Plot.ruleY([4], { strokeWidth: 1.5, stroke: "gray" }),
        Plot.line(
          [
            [1, 1],
            [6, 6],
          ],
          {
            strokeWidth: 1.5,
            stroke: "gray",
          }
        ),
        // codintionally adds the relevant marks
        filtered ? filter_marker : overall_marker,
      ],
    });

    containerRef.current.append(plot);
    return () => plot.remove();
  }, [dataset, filtered, selectedFilter]);

  return <div ref={containerRef} />;
};
