import { useSelector } from "react-redux";
import {
  SelectCurrentFilters,
  SelectAllFilters,
  SelectCurrentProject,
  SelectCurrentEmployees,
} from "../../features/projects/projectSlice";
import { selectActiveRole } from "../../features/auth/authSlice";
import { useGetInclusionQuery } from "../../features/report/inclusionApiSlice";
import Loader from "../Loader";
import ViolinLayout from "../../components/graph/ViolinLayout";
import HeatmapLayout from "../graph/HeatmapLayout";
import StackedVerticalBarChartLayout from "../graph/StackedVerticalBarChartLayout";
import FilterBar from "../../layout/FilterBar";
import {
  computeIngroupBias,
  inclusione_da_scelte,
  computeHeatmapInclusion,
} from "../functions/functions";
import { useMemo } from "react";

const Inclusion = () => {
  const selectedProject = useSelector(SelectCurrentProject);
  const selectedFilters = useSelector(SelectCurrentFilters);
  const filterArray = useSelector(SelectAllFilters);
  const activeRole = useSelector(selectActiveRole);
  const employees = useSelector(SelectCurrentEmployees);

  // Handle filter checking memoization
  const isFiltered = useMemo(() => {
    return (
      activeRole === "user_admin" &&
      selectedFilters.length > 0 &&
      selectedFilters[0] !== "Overall"
    );
  }, [activeRole, selectedFilters]);

  const { data, isLoading, isSuccess, isError, error } =
    useGetInclusionQuery(selectedProject);

  const selectedFilterKey = selectedFilters[0];
  const filter = filterArray[selectedFilterKey];

  let choices = null;
  let barChartData = null;
  let heatMapData = null;

  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    const employee_map = new Map(employees.map((e) => [e.id, e]));
    // Perform Preprocessing

    choices = inclusione_da_scelte(data.heatmap_data, employee_map, filter);

    barChartData = computeIngroupBias(
      choices.filter(
        (d) => (d.opzioni_miste === true) & (d.opzioni_con_stessa_cat === true)
      )
    );
    heatMapData = computeHeatmapInclusion(
      choices.filter((d) => d.opzioni_miste === true)
    );
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      <FilterBar selectedFilters={selectedFilters} />
      <div className="container">
        <div className="row">
          {data.scale_data &&
            data.scale_data.map((item, i) => (
              <ViolinLayout
                key={i}
                dataset={item}
                filtered={isFiltered}
                selected_filter={filter}
                userValue={data.scale_user_values[i]}
                benchmarkValue={data.scale_benchmark_values[i]}
                title={data.scale_titles[i]}
                info_button={true}
                scale_info={data.scale_descriptions[i]}
              />
            ))}

          {data.heatmap_data && activeRole === "user_admin" && (
            <StackedVerticalBarChartLayout
              dataset={barChartData}
              filtered={isFiltered}
              title={"Ingroup Bias"}
            />
          )}
          {data.heatmap_data && activeRole === "user_admin" && (
            <HeatmapLayout 
            dataset={heatMapData}
            filtered={isFiltered}
            title={"Inclusion heatmap"} />
          )}
        </div>
      </div>
    </>
  );
};

export default Inclusion;
