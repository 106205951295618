import { StackedVerticalBarChart } from "./StackedVerticalBarChart";

const StackedVerticalBarChartLayout = ({
  dataset,
  filtered,
  title = "Graph title",
  xtitle = "",
  ytitle = "",
  full_width = false,
}) => {
  let col_class = full_width ? "col-lg-12 mt-3" : "col-lg-6 mt-3";

  if (!filtered) return null;

  const config = {
    y: { domain: [0, 1] },
    height: 442,
    color: {
      legend: true,
      domain: ["Outgroup", "Ingroup"],
      range: ["lightblue", "orange"],
    },
  };

  return (
    <>
      {dataset && (
        <div className={col_class}>
          <div className="box">
          <div className="d-flex box-title justify-content-between">{title}</div>
            <StackedVerticalBarChart
              dataset={dataset}
              config={config}
              xtitle={xtitle}
              ytitle={ytitle}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default StackedVerticalBarChartLayout;
