import { ScatterDoubleContest } from "./ScatterDoubleContest";
import { ContestDataTable } from "../data-table/ContestDataTable.js";
import { useEffect, useState } from "react";
import * as d3 from "d3";
import { flattenNestedMap } from "../functions/functions.js";


const ScatterDoubleContestLayout = ({
  dataset,
  updateLabels,
  getLabels,
  graphTitles,
  tableTitle = "Tabel Title",
  xtitle = "Title x-axis",
  ytitle = "Title y-axis",
  full_width = false,
}) => {

  let col_class = "col-lg-6 mt-3";
  if (full_width) {
    col_class = "col-lg-12 mt-3";
  }

  // fallback for titles if titles are provided
  if (typeof graphTitles === "undefined") {
    graphTitles = [];
    dataset.forEach((value, index) => {
      graphTitles[index] = `Title ${index + 1}`;
    });
  }

  const [contestData, setContestData] = useState(null);

  useEffect(() => {
    setContestData(dataset);
  }, [dataset]);
 
  let groupedData = null;
  let dynamicColumns = [
    {
      Header: "Choice",
      accessor: "scelto",
      editable: false,
    },
  ];

  if (dataset) {
    const flattenedDataset = flattenNestedMap(dataset);
    const uniqueFilterValues = [...new Set(flattenedDataset.map((i) => i.target))];
    
    // label rater_pct with target value
    let labeledData = flattenedDataset.map((item) => {
      return { scelto: item["scelto"], [item["target"]]: item["rater_pct"] };
    });

    groupedData = Array.from(
      d3.group(labeledData, (d) => d.scelto),
      ([scelto, values]) => {
        // Merge all objects with the same "scelto" value
        return values.reduce((acc, curr) => Object.assign(acc, curr), {
          scelto,
        });
      }
    );

    // create table columns for all unique filter values
    uniqueFilterValues.forEach((k) =>
      dynamicColumns.push({
        Header: k === "costrutto" ? "Overall" : k,
        editable: false,
        accessor: k,
        disableFilters: true,
        Cell: ({ value }) => value ? Math.round(Number(value) * 100): 0,
      })
    );
  }

  return (
    <>
      {contestData && groupedData && (
        <ContestDataTable
          columns={dynamicColumns}
          updateLabels={updateLabels}
          data={groupedData}
          full_width={false}
          title={tableTitle}
        />
      )}

      {contestData &&
        contestData.map((e, i) => (
          <div key={"object_" + i} className={col_class}>
            <div className="box">
              <div className="box-title">{graphTitles[i]}</div>
              <ScatterDoubleContest
                dataset={e}
                getLabels={getLabels}
                labels={[]}
                xtitle={xtitle}
                ytitle={ytitle}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default ScatterDoubleContestLayout;
