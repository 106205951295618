import { useSelector } from "react-redux";
import {
  SelectCurrentProject,
} from "../../features/projects/projectSlice";
import { useState, useEffect } from "react";
import { useGetChangeAgentsQuery } from "../../features/report/changeAgentsApiSlice";
import Loader from "../Loader";
import ScatterStackedLayout from "../graph/ScatterStackedLayout";
import { DataTable } from "../data-table/DataTable";

const ChangeAgents = () => {
  const selectedProject = useSelector(SelectCurrentProject);
  const { data, isLoading, isSuccess, isError, error } =
  useGetChangeAgentsQuery(selectedProject);

  const [selectedPeople, setSelectedPeople] = useState(null);

  const titles = {
    title: "Impact across In-Degree",
    xtitle: "In-Degree",
    ytitle: "Impact",
  };
  const COLUMNS = [
    {
      Header: "Ranking",
      editable: false,
      accessor: "rank",
      disableFilters: true,
      Cell: ({ value }) => Number(value),
    },
    {
      Header: "Name",
      accessor: "label",
      editable: false,
    },
    {
      Header: "Impact score",
      accessor: "y",
      editable: false,
    },
  ];

  let input = [[], []];

  useEffect(() => {
    if (data) {
      setSelectedPeople(Math.ceil(data.length * 0.20));
    }
  }, [data]);

  function getScatterData() {
    input[1] = data.slice(0, selectedPeople);
    input[0] = data.slice(selectedPeople, data.length);
  }
  const exportToCsv = () => {
    
    const header = 'Ranking,Name,Impact Score\r\n';
    const rows = input[1].map((row, i) => `${i + 1},${row.label},${row.y}\r\n`).join('');
    const CsvString = `data:application/csv,${encodeURIComponent(header + rows)}`;
  
    // Create a temporary link and trigger the download
    const link = document.createElement('a');
    link.href = CsvString;
    link.download = 'change-agents.csv';
    document.body.appendChild(link);
    link.click();
  
    // Remove the link after the download is triggered
    document.body.removeChild(link);
  };
  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    // caltulate the size of the array and half it to the input selected
    getScatterData();
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  if (data && data.length === 0) return null;

  return (
    <>
      <div className="container py-3">
        <div className="col-sm-8">
          <div className="row">
            <div className="col text-center ">
              <label class="form-label" htmlFor="typeNumber">
                <strong>How many People do you want to select ? </strong>
              </label>
            </div>
            <div className="col-sm-2 ">
              <input
                min="1"
                max={data.length / 2}
                type="number"
                id="typeNumber"
                class="form-control"
                value={selectedPeople}
                onChange={(e) => {
                  setSelectedPeople(e.target.value);
                }}
              />
            </div>

            <div className="col-lg-4 ">
              <button className="btn btn-primary " onClick={exportToCsv}>
                Download
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-4  pt 2"></div>

      <div className="container">
        <div className="row">
        
          <DataTable
            columns={COLUMNS}
            data={input[1]}
            full_width={false}
            error={isError}
            loading={isLoading}
          />
          <ScatterStackedLayout
            dataset={input}
            scatterCodes={["pagerank","in_degree"]}
            filtered= {false}
            labeled={true}
            full_width={false}
            showlegend={false}
            dashLines = {false}
            titles={titles}
            title={"Impact across In-Degree"}
            xtitle={"In degree"}
            ytitle={"Impact"}
          />
        </div>
      </div>
      
      
    </>
  );
};

export default ChangeAgents;
