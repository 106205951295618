import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import { useEffect, useRef } from "react";

export const ScatterPlotlyTrendlineHorizontal = ({
  dataset,
  filtered,
  selectedFilter,
  scatterCodes = [],
  xtitle = "Title of X-axis",
  ytitle = "Title of Y-axis",
}) => {
  const containerRef = useRef();

  useEffect(() => {
    if (dataset === undefined) return;

    let max_total_pref =
      Math.ceil(d3.max(dataset.map((d) => d.receivable))) + 1;

    const plot = Plot.plot({
      color: {
        domain: ["to few ratings", "positive", "negative", "expected"],
        range: ["#cccccc", "green", "#fffa61", "#999999"],
      },
      marks: [
        Plot.axisX({
          anchor: "bottom",
          labelAnchor: "center",
          labelArrow: "None",
          label: xtitle,
          
        }),
        Plot.axisY({
          anchor: "left",
          labelAnchor: "center",
          labelArrow: "None",
          label: ytitle,
          
        }),
        Plot.areaY(
          [
            // ++++ need to compute max_total_pref from the data
            { x: 0, y1: -0.2, y2: 0.2 },
            { x: max_total_pref, y1: -0.2, y2: 0.2 },
          ],
          { x: "x", y1: "y1", y2: "y2", fill: "lightgray" }
        ),
        Plot.ruleY([0.2, -0.2]), // these are the two 20% lines
        Plot.dot(dataset, {
          x: "receivable",
          y: "performance",
          fill: "status",
          tip: true,
          channels: { name: "name", value: "recieved" },
        }),
        Plot.text(
          dataset,
          Plot.filter((d) => d.status === "positive", {
            x: "receivable",
            y: "performance",
            text: "name",
            textAnchor: "start",
            dy: -5,
          })
        ),
      ],
    });
    containerRef.current.append(plot);
    return () => plot.remove();
  }, [dataset, filtered, selectedFilter]);

  return <div ref={containerRef} />;
};
