import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogout } from "../../features/auth/authSlice";

import { useConfirmChangeMutation } from "../../features/auth/authApiSlice";

function ChangeEmail() {
  const initialized = useRef(false);
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [confirmChange] = useConfirmChangeMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    // failsave for react strict mode
    // preventing sending the token twice in development mode
    if (!initialized.current) {
      initialized.current = true;
      checkToken();
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const checkToken = async () => {
    try {
      const response = await confirmChange(token).unwrap();
      setTitle(response.message);
      setBody(response.body);
      if (response.completed) {
        dispatch(setLogout());
        dispatch({ type: "RESET" });
        setShowButton(true);
      } else{
        setShowButton(true);
      }
    } catch (err) {
      if (!err?.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg(err.data.message);
      } else if (err.status === 401) {
        setErrMsg("Request Failed");
      } else {
        setErrMsg("Request Failed");
      }
    }
  };

  return (
    <form
      id="home"
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div className="container vh-100">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="login-content style-2 ">
            <div className="row no-gutters">
              <div className="col-xl-12 bg-white">
                <div id="sign-in" className="auth-form form-validation">
                  {errMsg && (
                    <div className="login-error border p-1 my-2">{errMsg}</div>
                  )}
                  <h3 className="text-center mb-4 text-black">{title}</h3>

                  <p className="text-center mb-4 text-black">{body}</p>
                </div>
                <div className="text-center form-group mb-3">
                  {showButton && (
                    <button type="submit" className="btn btn-primary btn-block">
                      Home
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ChangeEmail;
