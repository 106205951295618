import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import { useEffect, useRef } from "react";

export const HeatmapChart = ({ dataset, config, xtitle, ytitle }) => {
  const containerRef = useRef();

  // data from the API of contest choices
  useEffect(() => {
    if (dataset === undefined) return;

    const plot = Plot.plot({
      ...config,
      marks: [
        Plot.axisX({
          anchor: "bottom",
          labelAnchor: "center",
          labelArrow: "None",
          fontSize: "14px",
          tickSpacing: 100,
          label: xtitle,
        }),
        Plot.axisY({
          anchor: "left",
          labelAnchor: "center",
          labelArrow: "None",
          fontSize: "14px",
          tickSpacing: 100,
          label: ytitle,
        }),
        Plot.cell(dataset, {
          x: "from",
          y: "scelto",
          fill: "pct_su_max",
        }),
        Plot.text(dataset, {
          x: "from",
          y: "scelto",
          text: (d) => d3.format(".1%")(d.pct_su_max),
          fill: (d) => (d.pct_su_max <= 0.3 ? "white" : "black"),
        }),
      ],
    });

    containerRef.current.append(plot);
    return () => plot.remove();
  }, [dataset]);

  return <div ref={containerRef} />;
};
