import { ScatterChart } from "./ScatterChart";

const ScatterLayout = ({
  dataset,
  filtered,
  selectedFilter,
  scatterCodes,
  titles = {
    title: "Title",
    xtitle: "x-axis",
    ytitle: "y-axis",
  },
  full_width = false,
}) => {
  let col_class = full_width ? "col-lg-12 mt-4" : "col-lg-6 mt-4";

  return (
    <>
      {dataset && (
        <div className={col_class}>
          <div className="box">
            <div className="box-title">{titles.title ? titles.title : ""}</div>
            <ScatterChart
              dataset={dataset}
              filtered={filtered}
              selectedFilter={selectedFilter}
              scatterCodes={scatterCodes}
              title={""}
              xtitle={titles.xtitle ? titles.xtitle : ""}
              ytitle={titles.ytitle ? titles.ytitle : ""}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ScatterLayout;
