import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  valuesLabels: [],
  behavioursLabels: [],
  workneedsLabels: [],
};

const contestSlice = createSlice({
  name: "contest",
  initialState,
  reducers: {
    updateValuesLabels: (state, action) => {
      state.valuesLabels = action.payload;
    },
    updateBehaviourLabels: (state, action) => {
        state.behavioursLabels = action.payload;
      },
      updateWorkneedsLabels: (state, action) => {
        state.workneedsLabels = action.payload;
      },
  },
});

export const { updateValuesLabels, updateBehaviourLabels, updateWorkneedsLabels } = contestSlice.actions;
export const SelectValuesLabels = (state) => state.contest.valuesLabels;
export const SelectBehavioursLabels = (state) => state.contest.behavioursLabels;
export const SelectWorkneedsLabels = (state) => state.contest.workneedsLabels;
export default contestSlice.reducer;
