import { HeatmapChart } from "./HeatmapChart";
import * as d3 from "d3";

const HeatmapLayout = ({
  dataset,
  filtered,
  title = "Graph Title",
  xtitle = "",
  ytitle = "",
  full_width = false,
}) => {
 

  if (!filtered) return null;

  let col_class = full_width ? "col-lg-12 mt-4" : "col-lg-6 mt-4";

  let config = {
    aspectRatio: 1.1,
    marginLeft: 140,
    padding: 0.05,
    color: {
      domain: [0, 1],
      scheme: "Viridis",
      legend: true,
      tickFormat: d3.format(".0%"),
    },
  };

  return (
    <>
      {dataset && (
        <div className={col_class}>
          <div className="box">
            <div className="box-title">{title}</div>
            <HeatmapChart
              dataset={dataset}
              config={config}
              xtitle={xtitle}
              ytitle={ytitle}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default HeatmapLayout;
