import * as Plot from "@observablehq/plot";
import { useEffect, useRef } from "react";

export const ScatterStackedChart = ({
  dataset,
  filtered,
  selectedFilter,
  scatterCodes = [],
  xtitle = "Title of X-axis",
  ytitle = "Title of Y-axis",
}) => {
  const containerRef = useRef();

  // only one mark that shows raw points

  // two marks, one for the averages, one for the filter level text

  useEffect(() => {
    if (dataset === undefined) return;

    const plot = 
    Plot.plot({
      marginBottom: 60,
      marginLeft: 80,
      marks: [
        
        Plot.axisX({
          anchor: "bottom",
          labelAnchor: "center",
          labelArrow: "None",
          fontSize: "14px",
          tickSpacing: 100,
          label: xtitle,
          
        }),
        Plot.axisY({
          anchor: "left",
          labelAnchor: "center",
          labelArrow: "None",
          fontSize: "14px",
          tickSpacing: 100,
          label: ytitle,  
        }),
        Plot.dot(
        // slice data keeping the NOT selected points
        dataset[0],
        {x: "in_degree", y: "pagerank", fill:"lightblue"}
        ),
        Plot.dot(
        // slice data keeping selected point and adding tip
        dataset[1], 
        {x: "in_degree", y: "pagerank", fill:"orange",
        tip: true, channels: {name:"label"}}
        )
      ]
    })

    containerRef.current.append(plot);
    return () => plot.remove();
  }, [dataset, filtered, selectedFilter]);

  return <div ref={containerRef} />;
};
