import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import { useEffect, useRef } from "react";

export const StackedVerticalBarChart = ({
  dataset,
  config = {},
  xtitle,
  ytitle,
}) => {
  const containerRef = useRef();

  useEffect(() => {
    if (dataset === undefined) return;

    const plot = Plot.plot({
      ...config,
      marks: [
        Plot.axisX({
          anchor: "bottom",
          labelAnchor: "center",
          labelArrow: "None",
          fontSize: "14px",
          tickSpacing: 100,
          label: xtitle,
        }),
        Plot.axisY({
          anchor: "left",
          labelAnchor: "center",
          labelArrow: "None",
          fontSize: "14px",
          tickSpacing: 100,
          label: ytitle,
        }),
        Plot.barY(dataset, {
          x: "from",
          y: "pct",
          fill: "scelta_stessa_cat",
          tip: true,
        }),
        Plot.text(
          dataset,
          Plot.stackY({
            x: "from",
            y: "pct",
            z: "scelta_stessa_cat",
            text: (d) => d3.format(".1%")(d.pct),
          })
        ),
        Plot.ruleY([0.5], { strokeDasharray: [5] }),
      ],
    });

    containerRef.current.append(plot);
    return () => plot.remove();
  }, [dataset]);

  return <div ref={containerRef} />;
};
